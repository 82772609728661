import React, { useEffect, useRef } from 'react';
import { DeepChat } from 'deep-chat-react';
import './style.css';


const App = () => {
  const apiKey = process.env.REACT_APP_OPEN_AI_API_KEY;
  return (
    <div className="App">
      <h1></h1>
      <deep-chat
        demo={false}
        style={{ borderRadius: '20px', width: '600px', height: '600px' }}
        introMessage='{"text": "Hi, ask me about Real World Evidence (RWE) studies and market access strategies!"}'
        directConnection={`{
    "openAI": {
      "key": "${apiKey}",
      "assistant": {
        "assistant_id": "asst_2DUFy8dVRjUTgPJdJgLmfM66"
      }}
  }`}
      ></deep-chat>
    </div>
  );
};
export default App; 